.H1-destaque {
    font-size: 5em;
    color: #333;
    line-height: 1.2;
    text-align: left;
}

.P-destaque {
    font-size: 1.5em;
    color: #666;
    margin-top: 10px;
}

.Span-produtos {
    color: #5500ff;
    font-weight: bold;
}

.Produtos {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
}

.Produtos-header {
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.Produtos-logo {
    width: 50px;
    margin-right: 20px;
    border-radius: 10px;
}

.Img-lateral {
    width: 50px;
    rotate: 90deg;
}

.Img-central {
    width: 100px;
    right: 10px;
    rotate: 90deg;
    align-items: center;
}

.Produtos-title {
    font-size: 1.8em;
    color: #333;
}

.Produtos-main {
    padding: 20px;
}

.Produtos-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Produtos-unic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Text-section {
    flex: 1;
    text-align: left;
}

.Text-section-centralized {
    flex: 1;
    text-align: center;
}

.Text-section h1 {
    font-size: 2em;
    color: #333;
}

.Text-section p,
.Text-section-centralized p {
    font-size: 1.5em; /* Mesma fonte do parágrafo destacado */
    color: #666;
    margin-top: 10px;
}

.Help-button {
    padding: 15px 30px;
    background-color: #5500ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
}

.Help-button:hover {
    background-color: #4400cc;
}

/* Estilos dos Cards */
.Cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
}

.Card {
    width: 300px;
    padding: 20px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.Card h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.Card p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.Card-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.Card-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.Card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Cores específicas dos Cards */
.Fisgados-card {
    background-color: #5500ff;
}

.Beeework-card {
    background-color: #ffcc00;
}

.Pomodoro-card {
    background-color: #00cc66;
}

.Shortlink-card {
    background-color: #3399ff;
}

.Comunic4-card {
    background-color: #ff6666;
}

@media (max-width: 768px) {
    .Produtos-content {
        flex-direction: column;
        align-items: center;
    }

    .Card-section {
        margin-top: 20px;
        justify-content: flex-start;
    }

    .H1-destaque {
        font-size: 3em; /* Ajusta o tamanho da fonte para dispositivos móveis */
        text-align: center;
    }

    .P-destaque,
    .Text-section p,
    .Text-section-centralized p {
        font-size: 1.2em; /* Ajusta o tamanho da fonte para dispositivos móveis */
        text-align: center;
    }

    .Cards-container {
        flex-direction: column;
        align-items: center;
    }

    .Card {
        width: 90%; /* Ajusta a largura dos cards para caberem melhor em dispositivos móveis */
    }
}
