/* Reseta margens e paddings */
html, div, p, img {
  margin-bottom: 20px;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 20px;
}

h1 {
  font-size: 3em;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.App {
  background-color: #ffffff;
  font-family: fredoka, sans-serif;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.header-button {
  background-color: #61dafb;
  border: none;
  padding: 10px 20px;
  color: #000000;
  font-size: 16px;
  cursor: pointer;
}

.App-body {
  background-color: #ffffff;
  color: dimgray;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.right-column {
  flex: 0.3;
  display: flex;
  justify-content: flex-end;
}

.logoQ-image {
  width: 100%;
  max-width: 100px; 
  margin-bottom: 20px;
  border-radius: 10px;
}

.text-container {
  max-width: 60%;
}

.body-paragraph {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.read-more-button {
  background-color: #ffffff;
  border: none;
  color: red;
  margin-left: 0px;
  padding: 0px 0px;
  cursor: pointer;
  font-size: 1em;
  font-family: 'Roboto';
}

.body-image {
  width: 100%;
  max-width: 300px; 
  align-self: flex-start;
}

.App-footer {
  background-color: #ffffff;
  color: #282c34;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
}

.footer-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.footer-links p {
  margin: 0;
  padding: 0;
}

.footer-links a {
  color: #636161;
  text-decoration: none;
  margin: 0;
}

.footer-links a:hover {
  text-decoration: underline;
}

.App-button-left {
  background-color: #ffffff;
  color: #5865f2;
  padding: 10px 20px;
  font-size: 80%;
  cursor: pointer;
  border-radius: 10px;
}

.App-button-left:hover {
  background-color: #5865f2;
  color: #ffffff;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .App-body {
    flex-direction: column;
    padding: 10px;
  }

  .logoQ-image {
    width: 20%;
  }

  .text-container {
    max-width: 100%;
  }

  .body-image {
    display: none;
  }

  .footer-links {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
