.Span-politica{
    color: #007bff;
    font-weight: bold;
}

.Span-termo{
    color: #037912;
    font-weight: bold;
}

.Politica {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Estilo do Cabeçalho */
  .Politica-header {
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .Politica-logo {
    width: 50px;
    margin-right: 20px;
    border-radius: 10px;
  }

  .Img-lateral{
    width: 50px;
    rotate: 90deg;
  }
  
  .Politica-title {
    font-size: 1.8em;
    color: #333;
  }
  
  /* Estilo do Corpo */
  .Politica-body {
    display: flex;
    height: calc(100vh - 100px); /* Define a altura para preencher a tela menos o header */
    padding: 20px;
    overflow: hidden; /* Impede que o conteúdo inteiro role */
  }
  
  /* Sumário à Esquerda */
  .Politica-nav {
    flex: 0.25;
    padding-right: 20px;
    border-right: 1px solid #ddd;
    position: sticky;
    top: 0;
    height: fit-content; /* Garante que o sumário não ocupe toda a altura da tela */
    align-self: flex-start;
  }
  
  .Politica-nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .Politica-nav li {
    margin-bottom: 10px;
  }
  
  .Politica-nav a {
    text-decoration: none;
    color: #636161;
    font-size: 1.1em;
  }

  .Politica-nav a:hover {
    text-decoration: none;
    color: #007bff;
    font-size: 1.1em;
  }
  
  .Politica-nav a:hover {
    text-decoration: underline;
  }
  
  /* Conteúdo à Direita */
  .Politica-content {
    flex: 0.75;
    padding-left: 20px;
    height: 100%;
    overflow-y: auto; /* O conteúdo dentro da área de texto pode rolar */
  }
  
  .Politica-content h2 {
    font-size: 1.6em;
    margin-top: 20px;
    color: #333;
  }
  
  .Politica-content h3 {
    font-size: 1.3em;
    margin-top: 15px;
    color: #444;
  }
  
  .Politica-content p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Responsividade para telas menores */
  @media (max-width: 768px) {
    .Politica-body {
      flex-direction: column;
      height: auto; /* Permite que o conteúdo cresça conforme necessário */
    }
  
    .Politica-nav {
      border-right: none;
      padding-right: 0;
      position: static; /* Remove o sticky em telas pequenas */
    }
  
    .Politica-content {
      padding-left: 0;
      height: auto;
      overflow-y: visible; /* Permite o scroll normal */
    }

    .Img-lateral {
        display: none;
      }
  }
  