.H1-destaque {
    font-size: 5em;
    color: #333;
    line-height: 1.2;
    text-align: left;
}

.P-destaque {
    font-size: 1.5em;
    color: #666;
    margin-top: 10px;
    text-align: left;
}

.Span-contato {
    color: #ff0000;
    font-weight: bold;
}

.Contato {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
}

.Contato-header {
    background-color: #f8f9fa;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}

.Contato-logo {
    width: 50px;
    margin-right: 20px;
    border-radius: 10px;
}

.Contato-header-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.Contato-title {
    font-size: 1.8em;
    color: #333;
    text-align: left;
    margin: 0;
    margin-right: 15px;
}

.Img-lateral {
    width: 50px;
    rotate: 90deg;
    margin: 0;
}

.Contato-main {
    padding: 20px;
}

.Contato-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.Text-section {
    flex: 1;
    text-align: left;
}

.Button-left {
    background-color: #ffffff;
    color: #5865f2;
    padding: 10px 20px;
    font-size: 80%;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.Button-left:hover {
    background-color: #5865f2;
    color: #ffffff;
}

.App-footer {
    padding: 20px;
    background-color: #f8f9fa;
    text-align: center;
    border-top: 1px solid #ddd;
}

.footer-links p {
    margin: 0;
    color: #666;
}

/* Responsividade */
@media (max-width: 768px) {
    .Contato-header {
        flex-direction: column;
        align-items: center;
    }

    .Contato-title {
        font-size: 1.5em;
        margin: 10px 0;
        text-align: center;
    }

    .H1-destaque {
        font-size: 3em;
        text-align: center;
    }

    .P-destaque {
        font-size: 1.2em;
        text-align: center;
    }

    .Img-lateral {
        margin-top: 10px;
    }
}
